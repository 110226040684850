function countSubmenus( submenu ) {
  return submenu.reduce((acc, el) => {
    el.classList.forEach((className) => {
      if (className === 'current-menu-item') {
        acc++;
      }
    });
    return acc;
  }, 0);
}

function clearActiveClass(submenu) {
  const clonedSubmenu = Array.from(submenu);
  return clonedSubmenu.map((item) => {
    item.classList.remove('current-menu-item');
    return item;
  });
}

function addActiveClass(menu) {
  const headerH = document.querySelector('.site-header').scrollHeight;
  const fromTop = window.scrollY + headerH;

  const test = menu.map((item) => {
    const link = item.querySelector('a');

    if (!link.hash) return item;

    const section = document.querySelector(link.hash);

    if ( section.offsetTop <= fromTop && section.offsetTop + section.offsetHeight > fromTop ) {
      item.classList.add('current-menu-item');
    } else {
      item.classList.remove('current-menu-item');
    }

    return item;
  });

  return test;
}

function addScrollListener(submenu) {

  let scrollTimer;

  window.addEventListener('scroll', () => {
    clearTimeout(scrollTimer);
    scrollTimer = setTimeout(() => {
      submenu = addActiveClass(submenu);
    }, 100);
  });
}

function init() {
  let currentSubmenu = document.querySelectorAll('#primaryMenu .current-menu-item .sub-menu li');
  currentSubmenu = Array.from(currentSubmenu);

  if (countSubmenus(currentSubmenu) <= 1) return;

  const cleanCurSubmenu = clearActiveClass(currentSubmenu);
  window.onload = addActiveClass(currentSubmenu);
  addScrollListener(cleanCurSubmenu);
}
export default init;
