import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

function init() {
  const doc = document.documentElement;
  const hamburger = document.querySelector('.siteNav-hamburger');
  const nav = document.querySelector('.site-navigation-container');
  const subNavs = document.getElementById('primaryMenu').querySelectorAll('.menu-item-has-children');
  const overlay = document.querySelector('.nav-mobile-overlay');

  let mobileNavIsOpen = false;
  let resizeTimer;

  function toggleSubNavClass(el) {

    if (!el.classList.contains('is-visible')) {

      el.classList.add('is-visible');
      el.style.height = 'auto';

      const height = `${el.scrollHeight}px`;
      el.style.maxHeight = height;
      // setTimeout(() => {
      //   el.style.maxHeight = height;
      // }, 10);

    } else {
      el.style.maxHeight = '0px';

      el.addEventListener('transitionend', () => {
        el.classList.remove('is-visible');
      }, {
        once: true,
      });
    }
  }

  function initMobileSubNavs() {
    // const currentMenu = document.querySelector('.current-menu-ancestor .sub-menu');
    // toggleSubNavClass(currentMenu);


    for (let index = 0; index < subNavs.length; index++) {
      const link = subNavs[index].querySelector('a');
      const subnav = subNavs[index].querySelector('.sub-menu');
      const subnavHrefs = subnav.querySelectorAll('a');

      link.addEventListener('click', (e) => {
        if (window.innerWidth < 1024) {
          e.preventDefault();
          toggleSubNavClass(subnav);
        }
      });

      // If the link is an anchor to the current page, close the nav
      for (let index = 0; index < subnavHrefs.length; index++) {
        if (subnavHrefs[index].anchor !== '') {
          subnavHrefs[index].addEventListener('click', (e) => {
            toggleClass();
          });
        }
      }
    }
  }

  // Toggle a body class which opens or closes the mobile nav
  function toggleClass(force) {

    if (!doc.classList.contains('mobile-nav-open') && force !== 'close') {
      doc.classList.add('mobile-nav-open');
      disableBodyScroll(nav);
      mobileNavIsOpen = true;
      initMobileSubNavs();

    } else {
      doc.classList.remove('mobile-nav-open');
      mobileNavIsOpen = false;
      enableBodyScroll(nav);
      clearAllBodyScrollLocks();
    }


  }

  //Events
  hamburger.onclick = (e) => {
    e.preventDefault();
    toggleClass();
  };

  document.onkeydown = (e) => {
    if (mobileNavIsOpen) return;

    e = e || window.event;
    let isEscape = false;

    if ('key' in e) {
      isEscape = (e.key === 'Escape' || e.key === 'Esc');
    } else {
      isEscape = (e.keyCode === 27);
    }

    if (isEscape) {
      toggleClass();
    }
  };

  window.onresize = () => {
    if (!mobileNavIsOpen) return;

    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => {
      toggleClass('close');
    }, 250);
  }

  overlay.onclick = () => { toggleClass('close'); };

}

export default init;
